import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { useAPI } from '../../hooks/api';
import { IAmplifyConfig } from '../../types/main';
import AddConfig from './Config/AddConfig';
import ConfigList from './Config/ConfigList';

const ConfigModal = (props: {
  onClose: () => void;
  name?: string;
  tenantId: string;
  configs?: IAmplifyConfig[];
}) => {
  const { addConfig, deleteConfig } = useAPI();

  const toast = useToast();
  return (
    <Modal isOpen={true} onClose={() => {}} size={'xl'}>
      <ModalOverlay />
      <ModalContent p="2">
        <ModalHeader>
          {!!props?.name ? `AWS Config von „${props.name}“ bearbeiten` : `AWS Config bearbeiten`}
        </ModalHeader>
        <ModalCloseButton onClick={props.onClose} />
        <ModalBody>
          <AddConfig
            tenantId={props.tenantId}
            onAdd={item => {
              addConfig(item).then(() => {
                toast({
                  title: 'Erfolgreich',
                  description: `Konfiguration von Tenant „${item.name}“ erfolgreich erstellt/aktualisiert`,
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
                props.onClose();
              });
            }}
          />
          <ConfigList
            items={props.configs || undefined}
            onDelete={item => deleteConfig(item).then(() => props.onClose())}
          />
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={props.onClose}>
            Schließen
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfigModal;
