import { Input, Select, Text } from '@chakra-ui/react';
import React from 'react';
import { ShiftScheduleProvider } from '../../types/main';

const ShiftScheduleProviderSelect = ({
  label,
  setValue,
  style,
  inputSize = 'md',
  isInvalid,
  initialValue
}: {
  label?: ShiftScheduleProvider;
  setValue: (val: any) => any;
  style?: React.CSSProperties;
  inputSize?: 'md' | 'lg' | 'sm' | 'xs' | undefined;
  isInvalid?: boolean;
  initialValue: ShiftScheduleProvider;
  }) => {
  
  const handleChangeProvider = (event: { target: { value: any } }) => {
    setValue({
      shiftScheduleEndpoint: initialValue?.shiftScheduleEndpoint,
      shiftScheduleProvider: event.target.value
    })
  }
  const handleChangeEndpoint = (event: { target: { value: any } }) => {
    setValue({
      shiftScheduleProvider: initialValue.shiftScheduleProvider,
      shiftScheduleEndpoint: event.target.value
    })
  }

  const statusStyle: React.CSSProperties = initialValue.shiftScheduleProvider === "" ? { backgroundColor: '#ddd' } : {};

  return (
    <div style={{ width: '100%' }}>
      {label && (
        <Text mb="1" mt="4" fontWeight="medium" color="blackAlpha.700">
          {label.shiftScheduleProvider}
        </Text>
      )}
      <Select onChange={handleChangeProvider} defaultValue={initialValue.shiftScheduleProvider} style={{ ...style }}>
        <option value="">Kein Dienstplan</option>
        <option value="Vivendi">Vivendi</option>
      </Select>
      {label && (
        <Text mb="1" mt="4" fontWeight="medium" color="blackAlpha.700">
          {label.shiftScheduleEndpoint}
        </Text>
      )}
      <Input
        disabled={initialValue.shiftScheduleProvider === ""}
        value={initialValue.shiftScheduleEndpoint}
        onChange={handleChangeEndpoint}
        placeholder=""
        size={inputSize}
        my={label ? undefined : '1'}
        style={{ ...style, ...statusStyle }}
        borderColor={isInvalid ? 'red.500' : undefined}
        focusBorderColor={isInvalid ? 'red.500' : undefined}
        borderWidth={isInvalid ? '1px' : undefined}
      />
    </div>
  );
};

export default ShiftScheduleProviderSelect;
