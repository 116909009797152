// 1. Import `extendTheme`
import { extendTheme } from '@chakra-ui/react';
// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  fonts: {
    heading: 'Inter',
    body: 'Inter',
  },
  colors: {
    brand: {
      50: '#fdfcfb',
      100: '#fbf0f3',
      200: '#f7cee6',
      300: '#eca0ca',
      400: '#ea70a9',
      500: '#dd4c8c',
      600: '#c7326a',
      700: '#a42073',
      800: '#731a31',
      900: '#45111a',
    },
  },
});
