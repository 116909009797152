import { Box } from '@chakra-ui/react';
import React from 'react';
import { ITenant } from '../../types/main';
import S3UploadButton from '../common/S3UploadButton';
import SimpleInput from '../common/SimpleInput';
import SimpleObjectInput from './SimpleObjectInput';
import ShiftScheduleProviderSelect from './ShiftScheduleProviderSelect';
import { v4 } from 'uuid';

const TenantForm = ({
  item,
  setItem,
}: {
  item: Partial<ITenant>;
  setItem: React.Dispatch<React.SetStateAction<ITenant>>;
}) => {
  const lockedProperties = ['tenantId'];

  const keyTranslations: any = {
    tenantId: 'Tenant Id',
    logo: 'Logo (800x500px, .png)',
    name: 'Bezeichnung',
    tenantAccountname: 'tenantAccountname (Firmen-Kennung)',
    minimumVersion: 'Minimum Version',
    shiftSchedule: {
      shiftScheduleProvider: 'Dienstplan-Provider',
      shiftScheduleEndpoint: 'Dienstplan-Endpoint',
    }
  };

  const keySorting: any = {
    tenantId: 6,
    logo: 5,
    name: 4,
    tenantAccountname: 4,
    minimumVersion: 2,
    shiftSchedule: 1,
  };

  const renderCustom: any = {
    config: () => <></>,
    logo: (item: Partial<ITenant>) => (
      <Box key={item.key} flexDirection="row" display="flex">
        <SimpleInput
          key={'logo'}
          disabled={lockedProperties.includes('logo') && !!item['logo']}
          label={keyTranslations['logo'] || 'logo'}
          value={item['logo']}
          setValue={newVal => setItem(prev => ({ ...prev, logo: newVal }))}
        />
        <Box alignSelf="flex-end" ml="4">
          <S3UploadButton
            onUpload={url =>
              setItem(prev => ({
                ...prev,
                logo: url,
              }))
            }
          />
        </Box>
      </Box>
    ),
  };

  return (
    <Box mt="-4">
      {Object.keys(item)
        .sort((a, b) => (keySorting[b] || 0) - (keySorting[a] || 0))
        .map((key) => 
          Object.keys(renderCustom).includes(key) ? (
            <>{renderCustom[key](item)}</>
          ) : (
            <>
              {key === 'shiftSchedule' ? (
                <ShiftScheduleProviderSelect
                  key={v4()}
                  label={keyTranslations[key]}
                  initialValue={item[key]!}
                  setValue={newVal => setItem(prev => ({ ...prev, shiftSchedule: newVal, }))}
                  isInvalid={!!item[key]?.shiftScheduleProvider && !item[key]?.shiftScheduleEndpoint}
                />
              ) : typeof item[key] === 'object' && key !== 'shiftSchedule' ? (
                <SimpleObjectInput
                  key={key}
                  label={key}
                  value={key === 'theme' && item.theme?.colors ? item.theme.colors : item[key]}
                  setValue={newVal =>
                    setItem(prev => ({
                      ...prev,
                      [key]: key === 'theme' ? { colors: newVal } : newVal,
                    }))
                  }
                />
                  ) : (
                <SimpleInput
                  key={key}
                  disabled={(lockedProperties.includes(key) && !!item[key])}
                  label={keyTranslations[key] || key}
                  value={
                    (typeof item[key] === 'string'
                      ? item[key]
                      : JSON.stringify(item[key], null, 4)) || ''
                  }
                  setValue={newVal => setItem(prev => ({ ...prev, [key]: newVal }))}
                />
              )}
            </>
        ),
      )}
      {
        item.shiftSchedule === undefined && (
          <ShiftScheduleProviderSelect
            key={v4()}
            label={
              {
              shiftScheduleProvider: 'Dienstplan-Provider',
              shiftScheduleEndpoint: 'Dienstplan-Endpoint',
            }
            }
            initialValue={{
              shiftScheduleProvider: "",
              shiftScheduleEndpoint: ""
            }}
            setValue={newVal => setItem(prev => ({ ...prev, shiftSchedule: newVal, }))}
          />
        )
      }
    </Box>
  );
};

export default TenantForm;
