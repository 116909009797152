import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';

import * as React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { theme } from './theme';
import { APIProvider } from './hooks/api';
import { DirtyLoginRouter } from './router/DirtyLoginRouter';

export const App = () => (
  <ChakraProvider theme={theme}>
    <APIProvider>
      <Box textAlign="center" fontSize="xl">
        <DirtyLoginRouter />
      </Box>
    </APIProvider>
  </ChakraProvider>
);
