import { Box, Button, useToast, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { HiPlus, HiRefresh } from 'react-icons/hi';
import { useAPI } from '../../hooks/api';
import { IAmplifyConfig, ITenant, Tenant } from '../../types/main';
import { Card } from '../lists/Card';
import { CardContent } from '../lists/CardContent';
import { CardHeader } from '../lists/CardHeader';
import { Property } from '../lists/Property';
import ConfigModal from './ConfigModal';
import TenantModal from './TenantModal';

export const TenantListCard = () => {
  const { listTenants, tenants, setTenants, updateTenant, deleteTenant } = useAPI();
  const [editTenant, setEditTenant] = React.useState<null | ITenant>(null);
  const [editConfig, setEditConfig] = React.useState<null | {
    name: string;
    tenantId: string;
    configs?: IAmplifyConfig[];
  }>(null);
  const [newTenant, setNewTenant] = React.useState<null | ITenant>(null);
  const [isLoading, setLoading] = React.useState(false);
  const toast = useToast();

  const handleRefresh = async () => {
    setLoading(true);
    const res = await listTenants();
    setTenants(res);
    setLoading(false);
  };

  const handleSave = (item: ITenant) => {
    // eslint-disable-next-line no-restricted-globals
    let confirmed = confirm(JSON.stringify(item, null, 4));
    if (confirmed) {
      updateTenant(item)
        .then(() => {
          toast({
            title: 'Erfolgreich',
            description: `Konfiguration von Tenant „${item.name}“ erfolgreich erstellt/aktualisiert`,
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
          setEditTenant(null);
          setNewTenant(null);
          handleRefresh();
        })
        .catch(() => {
          toast({
            title: 'Fehlgeschlagen',
            description: `Konfiguration von Tenant „${item.name}“ konnte nicht erstellt/geändert werden`,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        });
    }
  };

  const handleDelete = (item: ITenant) => {
    deleteTenant(item)
      .then(() => {
        toast({
          title: 'Erfolgreich',
          description: `Tenant „${item.name}“ erfolgreich gelöscht`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        setEditTenant(null);
        setNewTenant(null);
        handleRefresh();
      })
      .catch(() => {
        toast({
          title: 'Fehlgeschlagen',
          description: `Tenant „${item.name}“ konnte nicht gelöscht werden`,
          status: 'error',
          duration: 11000,
          isClosable: true,
          position: 'top-right',
        });
      });
  };

  React.useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box as="section" bg={useColorModeValue('gray.100', 'inherit')} py="12" px={{ md: '8' }}>
      <Card maxW="3xl" mx="auto">
        <CardHeader
          title="Tenants"
          action={
            <Box>
              <Button
                isLoading={isLoading}
                colorScheme="brand"
                mr="2"
                minW="26"
                leftIcon={<HiPlus size="18" />}
                onClick={() => setNewTenant(new Tenant(true))}>
                Neuer Tenant
              </Button>
              <Button
                isLoading={isLoading}
                variant="outline"
                minW="26"
                leftIcon={<HiRefresh size="18" />}
                onClick={() => handleRefresh()}>
                Aktualiseren
              </Button>
            </Box>
          }
        />
        <CardContent>
          {tenants?.map(item => (
            <Property
              key={item.name}
              item={item}
              onEdit={(item: ITenant) => setEditTenant(item)}
              onEditConfig={(item: ITenant) =>
                setEditConfig({ tenantId: item.tenantId, configs: item.config, name: item.name })
              }
            />
          ))}
        </CardContent>
      </Card>
      {!!editConfig && (
        <ConfigModal
          tenantId={editConfig.tenantId}
          configs={editConfig.configs}
          onClose={() => {
            setEditConfig(null);
            handleRefresh();
          }}
        />
      )}
      {!!newTenant && (
        <TenantModal
          item={newTenant}
          onClose={() => setNewTenant(null)}
          onSave={handleSave}
          creatingNew={true}
        />
      )}
      {!!editTenant && (
        <TenantModal
          item={editTenant}
          onClose={() => setEditTenant(null)}
          onSave={handleSave}
          onDelete={handleDelete}
        />
      )}
    </Box>
  );
};
