import { CloseButton, Flex, Text, Divider, Button, Box } from '@chakra-ui/react';
import React from 'react';
import { ObjectInput } from 'react-object-input';
import SimpleInput from '../common/SimpleInput';

const SimpleObjectInput = ({
  label,
  value,
  setValue,
  level = 0,
}: {
  label: string;
  value: any;
  setValue: (val: any) => any;
  level?: number;
}) => {
  // console.log(JSON.stringify({ obj: value, tof: typeof value, label }, null, 4));
  const bg = '#ffffff';
  // `hsl(297deg ${60 + 30 * level}% 64% / 26%)`;
  const objLabels = {
    addLabel: { theme: 'Farbe hinzufügen', flags: 'Flag hinzufügen' },
    label: { theme: 'Theme-Colors', flags: 'Tenant Config Flags' },
    empty: {
      theme: 'Für die Primärfarbe, Element "primary" anlegen',
      flags:
        'Flags auf die in der App zugegriffen werden können, z.B. welche features freigeschalten sind',
    },
  };
  return (
    <div style={{ width: '100%' }}>
      {(label === 'theme' || label === 'flags') && <Text mt="3">{objLabels.label[label]}</Text>}
      <ObjectInput
        obj={value}
        renderAdd={add => (
          <Button mt="1" onClick={add} size="sm">
            {/* @ts-ignore */}
            {label in objLabels.addLabel ? objLabels.addLabel[label] : `Element hinzufügen`}
          </Button>
        )}
        renderEmpty={() => (
          <Box
            w="100%"
            borderWidth="2px"
            borderColor="blackAlpha.400"
            borderRadius="lg"
            borderStyle="dashed"
            px="3"
            py="2"
            my="1">
            {(label === 'theme' || label === 'flags') && <Text>{objLabels.empty[label]}</Text>}
          </Box>
        )}
        onChange={updater => {
          let res = updater(value || {});
          console.log(JSON.stringify({ res }, null, 4));
          setValue(res);
        }}
        renderItem={(propKey, propValue: any, updateKey, updateValue, deleteProperty) => (
          <Flex wrap="nowrap">
            <SimpleInput
              style={{
                width: typeof propValue === 'object' ? '100%' : undefined,
                backgroundColor: bg,
              }}
              label={''}
              value={propKey}
              setValue={newVal => updateKey(newVal)}
            />
            <Text mx="2" my="auto" fontWeight="bold">
              {':'}
            </Text>
            {typeof propValue === 'object' ? (
              <SimpleObjectInput
                label={propKey}
                value={propValue}
                setValue={newVal => updateValue(newVal)}
                level={level + 1}
              />
            ) : (
              <>
                <SimpleInput
                  label={''}
                  value={propValue || ''}
                  setValue={newVal => {
                    console.log(JSON.stringify({ newVal, propValue, propKey }, null, 4));
                    updateValue(newVal);
                  }}
                  style={{
                    color:
                      typeof propValue === 'string' &&
                      propValue.indexOf('#') === 0 &&
                      propValue.length === 7
                        ? '#ffffff'
                        : '#000000',
                    width: typeof propValue === 'object' ? '100%' : undefined,
                    backgroundColor:
                      typeof propValue === 'string' &&
                      propValue.indexOf('#') === 0 &&
                      propValue.length === 7
                        ? propValue
                        : bg,
                  }}
                />
              </>
            )}
            <CloseButton size="md" onClick={deleteProperty} colorScheme="purple" ml="1" my="auto" />
          </Flex>
        )}
      />
      {label === 'theme' && <Divider height="1px" bgColor="blackAlpha.500" mt="4" />}
    </div>
  );
};

export default SimpleObjectInput;
