import { Button, Divider, Flex, Text, Textarea } from '@chakra-ui/react';
import React, { useState } from 'react';
import { IAmplifyConfig } from '../../../types/main';
import SimpleInput from '../../common/SimpleInput';

const AddConfig = (props: {
  tenantId: string;
  onDiscard: () => void;
  onAdd: (item: IAmplifyConfig) => void;
}) => {
  const [item, setItem] = useState<Partial<IAmplifyConfig>>({ tenantId: props.tenantId });

  const validateAwsmobile = (val?: string) => {
    if (!val) return false;
    try {
      return typeof JSON.parse(val) === 'object';
    } catch {
      return false;
    }
  };

  const validateVersioncode = (val?: string) => {
    if (!val) return false;
    return /^(\d+\.)(\d+\.)(\*|\d+)$/.test(val);
  };

  return (
    <>
      <Flex direction="row" gridGap="6">
        <SimpleInput
          inputSize="sm"
          disabled
          label={'tenantId'}
          value={props.tenantId}
          setValue={newVal => setItem(prev => ({ ...prev, tenantId: newVal }))}
        />
        <SimpleInput
          inputSize="sm"
          label={'Versionsnummer (x.x.x)'}
          value={'version' in item ? item.version : ''}
          setValue={newVal => setItem(prev => ({ ...prev, version: newVal }))}
          isInvalid={!('version' in item) || !validateVersioncode(item.version)}
        />
      </Flex>
      <div>
        <Text mb="1" mt="4" fontWeight="medium" color="blackAlpha.700">
          AWS-Config
        </Text>
        <Textarea
          size="xs"
          isInvalid={'awsmobile' in item && !validateAwsmobile(item.awsmobile)}
          borderColor={
            'awsmobile' in item && validateAwsmobile(item.awsmobile) ? 'green.500' : undefined
          }
          focusBorderColor={
            'awsmobile' in item && validateAwsmobile(item.awsmobile) ? 'green.500' : 'red.500'
          }
          borderWidth="1px"
          borderRadius="4px"
          placeholder={`{ 
    "aws_mobile_analytics_app_id": "xxxx", 
    "aws_user_pools_id": "eu-central-1_xxxx",
    ...`}
          value={'awsmobile' in item ? item.awsmobile : ''}
          onChange={(event: { target: { value: any } }) =>
            setItem(prev => ({ ...prev, awsmobile: event.target.value }))
          }
        />
        <Button
          mt="4"
          colorScheme="brand"
          disabled={
            !('awsmobile' in item) ||
            !('version' in item) ||
            !('tenantId' in item) ||
            !validateAwsmobile(item.awsmobile) ||
            !validateVersioncode(item.version)
          }
          onClick={() =>
            props.onAdd({ ...item, awsmobile: JSON.parse(item.awsmobile) } as IAmplifyConfig)
          }>
          Speichern
        </Button>
        <Button mt="4" ml="4" variant="outline" onClick={() => props.onDiscard()}>
          Abbrechen
        </Button>
        <Divider mt="6" height="1px" bgColor="blackAlpha.500" />
      </div>
    </>
  );
};

export default AddConfig;
