import { Box, Button, Flex, FlexProps, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { HiCode, HiPencilAlt } from 'react-icons/hi';
import { ITenant } from '../../types/main';

interface Props extends FlexProps {
  item: ITenant;
  onEdit: (item: ITenant) => void;
  onEditConfig: (item: ITenant) => void;
}

export const Property = (props: Props) => {
  const bg = useColorModeValue('gray.50', 'gray.600');

  const label = props.item.name;
  // const values = Object.keys(props.item).map(key => {
  //   return (
  //     key +
  //     ': ' +
  //     (typeof props.item[key] === 'string'
  //       ? props.item[key]
  //       : JSON.stringify(props.item[key], null, 4))
  //   );
  // });
  return (
    <Flex
      as="dl"
      direction={'row'}
      px="6"
      py="4"
      _even={{ bg: bg }}
      fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}>
      {/* @ts-ignore */}
      <Box as="dt" textAlign="left" flexGrow="1" fontWeight="semibold" my="auto">
        {label}
      </Box>
      {/* <Flex as="dl" direction={{ base: 'row' }} _even={{ bg: bg }}> */}
      <Button
        size="sm"
        variant={props.item.config && props.item.config?.length > 0 ? 'outline' : 'solid'}
        colorScheme={props.item.config && props.item.config?.length > 0 ? undefined : 'red'}
        maxW={props.item.config && props.item.config?.length > 0 ? '28' : '40'}
        leftIcon={<HiCode size="18" />}
        onClick={() => props.onEditConfig(props.item)}>
        {props.item.config && props.item.config?.length > 0
          ? props.item.config[0].version
          : 'unkonfiguriert'}
      </Button>
      <Button
        size="sm"
        variant="outline"
        colorScheme="brand"
        ml="4"
        w="32"
        rightIcon={<HiPencilAlt size="18" />}
        onClick={() => props.onEdit(props.item)}>
        Bearbeiten
      </Button>
      {/* <Box marginLeft="8" my="auto">
          <HiPencilAlt style={{ marginBottom: '2px' }} onClick={() => props.onEdit(props.item)} />
        </Box> */}
      {/* </Flex> */}
      {/* <Flex
        as="dl"
        direction={{ base: 'row', sm: 'column' }}
        px="6"
        py="4"
        _even={{ bg: useColorModeValue('gray.50', 'gray.600') }}
        {...flexProps}>
        {values.map(val => (
          <Box as="dd" flex="1" fontSize="">
            {val}
          </Box>
        ))}
      </Flex> */}
    </Flex>
  );
};
