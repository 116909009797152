import { v4 } from 'uuid';

export interface IAmplifyConfig {
  tenantId: string;
  version: string;
  awsmobile: any;
  createdAt?: string;
  [key: string]: any;
}

export interface ITenant {
  theme: {
    colors?: {
      primary: string | undefined;
      [key: string]: string | undefined;
    };
    [key: string]: any;
  };
  tenantAccountname: string;
  tenantId: string;
  logo: string;
  name: string;
  minimumVersion: string;
  flags?: {
    [key: string]: any;
  };
  config?: IAmplifyConfig[] | [];
  shiftSchedule: ShiftScheduleProvider;
  [key: string]: any;
}

export class Tenant implements ITenant {
  public theme: {
    colors?: {
      primary: string | undefined;
      [key: string]: string | undefined;
    };
    [key: string]: any;
  };
  public tenantAccountname: string;
  public tenantId: string;
  public logo: string;
  public name: string;
  public minimumVersion: string;
  public flags?: {
    [key: string]: any;
  };
  public config?: IAmplifyConfig[] | [];
  public shiftSchedule: ShiftScheduleProvider;
  public constructor (generateUuid?: boolean) {
    this.theme = { colors: { primary: '' } };
    this.tenantAccountname = '';
    this.tenantId = generateUuid ? v4() : '';
    this.logo = '';
    this.name = '';
    this.minimumVersion = '';
    this.flags = {};
    this.config = [];
    this.shiftSchedule = {
      shiftScheduleProvider: '',
      shiftScheduleEndpoint: '',
    }
  }
}

export type ShiftScheduleProvider = {
  shiftScheduleProvider: string;
  shiftScheduleEndpoint: string;
} | {
  shiftScheduleProvider: undefined;
  shiftScheduleEndpoint: undefined;
}