import {
  Button,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { HiUpload } from 'react-icons/hi';
import { useFilePicker } from 'use-file-picker';
import { useAPI } from '../../hooks/api';

const S3UploadButton = ({ onUpload }: { onUpload: (url: string) => void }) => {
  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    readAs: 'ArrayBuffer',
    accept: '.png',
  });

  const { uploadFile } = useAPI();

  const getImageFromBlob = (data: any) => {
    const blob = new Blob([data]);
    return URL.createObjectURL(blob);
  };

  return (
    <>
      <Popover placement="top-end">
        <PopoverTrigger>
          <IconButton
            aria-label="upload"
            icon={<HiUpload size="20" onClick={() => openFileSelector()} />}
          />
        </PopoverTrigger>
        {filesContent[0]?.content && (
          <PopoverContent shadow="lg">
            <PopoverArrow />
            <PopoverHeader>Bild hochladen?</PopoverHeader>
            <PopoverCloseButton color="white" />
            <PopoverBody>
              <>
                <Image src={getImageFromBlob(filesContent[0]?.content)} alt="Image to upload" />
                <Text textAlign="center" mb="3" mt="1" fontSize="sm">
                  {filesContent[0]?.name}
                </Text>
                <Button
                  onClick={() => {
                    uploadFile(`tenants/${filesContent[0]?.name}`, filesContent[0]?.content)
                      .then((res: any) => {
                        if (!res?.key && !res?.Key)
                          alert(
                            'Image upload failed successfully: ' + JSON.stringify(res, null, 4),
                          );
                        else {
                          onUpload(`https://static.luciapp.de/${res?.key || res?.Key}`);
                          clear();
                        }
                      })
                      .catch(err => alert('Image upload failed: ' + JSON.stringify(err, null, 4)));
                  }}>
                  Bestätigen
                </Button>
              </>
            </PopoverBody>
          </PopoverContent>
        )}
      </Popover>
    </>
  );
};

export default S3UploadButton;
