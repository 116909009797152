import { Box, Heading, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { Card } from './Card';
import { LoginForm } from './LoginForm';

export const LoginScreen = () => (
  <Box
    bg={useColorModeValue('gray.50', 'inherit')}
    minH="100vh"
    py="12"
    px={{ base: '4', lg: '8' }}>
    <Box maxW="md" mx="auto">
      <Heading textAlign="center" size="lg" fontWeight="extrabold" mb={{ base: '10' }}>
        LUCI Tenant Management
      </Heading>
      <Card>
        <LoginForm />
      </Card>
    </Box>
  </Box>
);
