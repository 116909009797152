import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { HiTrash } from 'react-icons/hi';
import { ITenant } from '../../types/main';
import TenantForm from './TenantForm';

const TenantModal = (props: {
  onClose: () => void;
  onSave: (item: ITenant) => void;
  onDelete?: (item: ITenant) => void;
  item: ITenant;
  creatingNew?: boolean;
}) => {
  const [item, setItem] = useState<Partial<ITenant>>(props.item);

  const handleSave = (toSave: Partial<ITenant> | null) => {
    if (!toSave) {
      alert('Nothing to save, something might be broken');
      return null;
    }
    const requiredFields = ['logo', 'minimumVersion', 'name', 'tenantAccountname', 'tenantId'];
    let missingFields: any = [];
    requiredFields.forEach(field => {
      if (!toSave[field] || !toSave[field].trim()) missingFields.push(field);
      else toSave[field] = toSave[field].trim();
    });
    if (missingFields.length > 0) {
      alert('Missing required fields: ' + JSON.stringify(missingFields, null, 4));
      return null;
    } else {
      props.onSave(toSave as ITenant);
    }
  };

  return (
    <Modal isOpen={true} onClose={() => {}} size={'xl'}>
      <ModalOverlay />
      <ModalContent p="2">
        <ModalHeader>
          {!!props.item?.name ? `Tenant „${props.item.name}“ bearbeiten` : `Neuen Tenant erstellen`}
        </ModalHeader>
        <ModalCloseButton onClick={props.onClose} />
        <ModalBody>
          <TenantForm item={{ ...item, flags: item.flags || {} }} setItem={setItem} />
        </ModalBody>

        <ModalFooter>
          {!props.creatingNew && (
            <Popover placement="top-end">
              <PopoverTrigger>
                <IconButton
                  mr="auto"
                  colorScheme="red"
                  variant="outline"
                  aria-label="delete"
                  icon={<HiTrash style={{ marginBottom: '2px' }} />}
                />
              </PopoverTrigger>
              <PopoverContent shadow="lg">
                <PopoverArrow />
                <PopoverHeader backgroundColor="red.500" color="white">
                  Tenant {props.item.name} wirklich löschen
                </PopoverHeader>
                <PopoverCloseButton color="white" />
                <PopoverBody>
                  <Button
                    mr="auto"
                    variant="outline"
                    colorScheme="brand"
                    onClick={() => {
                      let t = prompt('Bitte geben sie den tenantAccountname ein');
                      if (t === props.item.tenantAccountname && props.onDelete) {
                        props.onDelete(props.item);
                      }
                    }}>
                    Bestätigen
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}
          <Button colorScheme="brand" mr={3} onClick={() => handleSave(item)}>
            Speichern
          </Button>
          <Button variant="ghost" onClick={props.onClose}>
            Verwerfen
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TenantModal;
