import { Box, Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { IAmplifyConfig } from '../../../types/main';
import ConfigForm from './ConfigForm';

const AddConfig = (props: { tenantId: string; onAdd: (item: IAmplifyConfig) => void }) => {
  const [isCreating, setCreating] = useState(false);

  return (
    <Box>
      {isCreating ? (
        <Box mt="-4">
          <ConfigForm
            tenantId={props.tenantId}
            onAdd={val => props.onAdd(val)}
            onDiscard={function (): void {
              setCreating(false);
            }}
          />
        </Box>
      ) : (
        <Button
          ml="auto"
          size="sm"
          colorScheme="brand"
          mr="2"
          minW="26"
          leftIcon={<HiPlus size="18" />}
          onClick={() => setCreating(true)}>
          Neue Version
        </Button>
      )}
    </Box>
  );
};

export default AddConfig;
