import {
  Box,
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { HiClipboard, HiTrash } from 'react-icons/hi';
import { IAmplifyConfig } from '../../../types/main';

const ConfigList = (props: {
  onDelete: (item: IAmplifyConfig) => void;
  items?: IAmplifyConfig[];
}) => {
  const toast = useToast();

  const ConfigItem = ({ el }: { el: IAmplifyConfig }) => {
    return (
      <Flex
        as="dl"
        direction={{ base: 'column', sm: 'row' }}
        px="6"
        py="4"
        _even={{ bg: 'gray.50' }}>
        {/* @ts-ignore */}
        <Box as="dt" textAlign="left" flexGrow="1" fontWeight="semibold" my="auto">
          {el.version}
        </Box>
        <Flex as="dl" direction={{ base: 'row' }} _even={{ bg: 'gray.50' }}>
          <Box as="dd" flex="1" my="auto">
            {el?.createdAt ? new Date(el.createdAt).toUTCString() : ''}
          </Box>
          <Box marginLeft="8" my="auto">
            <Popover placement="right" computePositionOnMount={true}>
              <PopoverTrigger>
                <IconButton
                  mr="4"
                  colorScheme="red"
                  variant="outline"
                  aria-label="delete"
                  icon={<HiTrash style={{ marginBottom: '2px' }} />}
                />
              </PopoverTrigger>
              <PopoverContent ml="32" mt="32">
                <PopoverArrow />
                <PopoverHeader>Version {el.version} wirklich löschen</PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody>
                  <Button variant="outline" colorScheme="brand" onClick={() => props.onDelete(el)}>
                    Bestätigen
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <IconButton
              aria-label="copy"
              onClick={() => {
                navigator.clipboard.writeText(
                  JSON.stringify(el.awsmobile, null, 4)
                    .substring(1, JSON.stringify(el.awsmobile, null, 4).length - 1)
                    .replaceAll('\\n', '\n')
                    .replaceAll('\\"', '"'),
                );
                toast({
                  title: 'Kopiert',
                  description: 'Die AWS-Config wurde in die Zwischenablage kopiert',
                  position: 'top',
                  status: 'success',
                  duration: 4000,
                  isClosable: true,
                });
              }}
              icon={<HiClipboard />}
            />
          </Box>
        </Flex>
      </Flex>
    );
  };
  return <Box>{props.items && props.items.map(item => <ConfigItem el={item} />)}</Box>;
};

export default ConfigList;
