/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useContext, useState } from 'react';
import AWS, { Credentials, DynamoDB } from 'aws-sdk';
import { IAmplifyConfig, ITenant } from '../types/main';
import aws4 from 'aws4';

const useProvideAPI = () => {
  const [isAuthenticated, setAuthenticated] = useState<boolean>();
  const [isAuthLoading, setAuthLoading] = useState<boolean>();
  const [tenants, setTenants] = React.useState<Array<ITenant> | null>(null);
  const [tableNames, setTableNames] = useState<null | {
    tenantsTable: string;
    configTable: string;
    assetBucket: string;
  }>(null);

  const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: '2012-08-10' });
  const s3 = new AWS.S3();

  const login = (
    username: string,
    password: string,
  ): Promise<{ success: boolean; message: string | null }> => {
    setAuthLoading(true);

    AWS.config = new AWS.Config();
    AWS.config.credentials = new Credentials({
      accessKeyId: username,
      secretAccessKey: password,
    });
    AWS.config.region = 'eu-central-1';

    const sts = new AWS.STS();
    return sts
      .getCallerIdentity()
      .promise()
      .then(response => {
        if (response?.UserId) {
          setAuthenticated(true);
          setAuthLoading(false);
          return { success: true, message: null };
        } else {
          const res = {
            success: true,
            message: 'Sucessfully got Caller Identity but something else might be wrong',
          };
          console.warn(JSON.stringify({ ...res, response }, null, 4));
          return res;
        }
      })
      .catch(error => {
        setAuthLoading(false);
        console.log(JSON.stringify({ error }, null, 4));
        return { success: false, message: 'Authentication failed' };
      });
  };

  const loadTables = (): Promise<null | {
    tenantsTable: string;
    configTable: string;
    assetBucket: string;
  }> => {
    const opts: any = {
      method: 'GET',
      host: 'admin-api.luciapp.de',
      path: '/meta',
      service: 'execute-api',
      region: 'eu-central-1',
    };
    const cred = AWS.config.credentials;
    aws4.sign(opts, {
      accessKeyId: cred?.accessKeyId,
      secretAccessKey: cred?.secretAccessKey,
    });

    return fetch('https://admin-api.luciapp.de/meta', {
      method: 'GET',
      headers: opts.headers,
    })
      .then(response => response.json())
      .then(response => {
        setTableNames(response);
        return response;
      })
      .catch(err => {
        console.error(err);
        return null;
      });
  };

  const checkTables = async () => {
    return tableNames || (await loadTables());
  };

  const listTenants = async (): Promise<Array<ITenant>> => {
    let tbls = await checkTables();

    if (!tbls) return [];

    const loadConfigs = (tenantId: string, table: string) => {
      return docClient
        .query({
          TableName: table,
          ExpressionAttributeValues: {
            ':v1': tenantId,
          },
          KeyConditionExpression: 'tenantId = :v1',
          Limit: 500,
        })
        .promise()
        .then((data: any) => {
          return (data?.Items || []) as Promise<IAmplifyConfig[] | []>;
        });
    };

    const params: DynamoDB.DocumentClient.ScanInput = {
      TableName: tbls.tenantsTable,
      Limit: 500,
    };

    return docClient
      .scan(params)
      .promise()
      .then((data: any) => {
        if (!data?.Items) return [];
        const promises: Promise<ITenant>[] = [];
        data.Items.forEach((item: ITenant) => {
          promises.push(
            new Promise(async resolve => {
              let res: ITenant = item;
              if (item.tenantId) {
                res.config = await loadConfigs(item.tenantId, tbls!.configTable);
                if (res.config && res.config.length > 1) {
                  res.config = res.config.sort(
                    (a, b) =>
                      parseInt(b.version?.replaceAll('.', '')) -
                      parseInt(a.version?.replaceAll('.', '')),
                  );
                }
              }
              resolve(res);
            }),
          );
        });
        return Promise.all(promises);
      });
  };

  const checkIfTenantExists = (tenantId: string): boolean => {
    if (!tenants || !tenantId) return false;
    let found = false;
    tenants.forEach(x => {
      if (x.tenantId && x.tenantId.trim() === tenantId.trim()) found = true;
    });
    return found;
  };

  const updateTenant = async (data: ITenant) => {
    let tbls = await checkTables();
    if (!tbls) return [];

    const params: DynamoDB.DocumentClient.PutItemInput = {
      TableName: tbls?.tenantsTable,
      Item: data,
    };

    return docClient
      .put(params)
      .promise()
      .then(data => {
        console.log(JSON.stringify({ data }, null, 4));
      })
      .catch(error => console.error(JSON.stringify({ error }, null, 4)));
  };

  const createTenant = (data: ITenant) => {
    return updateTenant(data);
  };

  const addConfig = async (data: IAmplifyConfig) => {
    let tbls = await checkTables();
    if (!tbls) return [];

    const params: DynamoDB.DocumentClient.PutItemInput = {
      TableName: tbls?.configTable,
      Item: { ...data, createdAt: new Date().toISOString() },
    };

    return docClient
      .put(params)
      .promise()
      .then(data => {
        console.log(JSON.stringify({ data }, null, 4));
      })
      .catch(error => console.error(JSON.stringify({ error }, null, 4)));
  };

  const deleteConfig = async (data: IAmplifyConfig) => {
    let tbls = await checkTables();
    if (!tbls) return [];

    const params: DynamoDB.DocumentClient.DeleteItemInput = {
      TableName: tbls?.configTable,
      Key: { tenantId: data.tenantId, version: data.version },
    };

    return docClient
      .delete(params)
      .promise()
      .then(data => {
        console.log(JSON.stringify({ data }, null, 4));
      })
      .catch(error => console.error(JSON.stringify({ error }, null, 4)));
  };

  const deleteTenant = async (data: ITenant) => {
    let tbls = await checkTables();
    if (!tbls) return [];

    const params: DynamoDB.DocumentClient.DeleteItemInput = {
      TableName: tbls?.tenantsTable,
      Key: { tenantAccountname: data.tenantAccountname },
    };

    return docClient
      .delete(params)
      .promise()
      .then(data => {
        console.log(JSON.stringify({ data }, null, 4));
      })
      .catch(error => console.error(JSON.stringify({ error }, null, 4)));
  };

  const uploadFile = async (key: string, data: any) => {
    let tbls = await checkTables();
    if (!tbls) return [];
    return s3.upload({ Bucket: tbls.assetBucket, Key: key, Body: data }).promise();
  };

  return {
    isAuthenticated,
    isAuthLoading,
    tenants,
    checkIfTenantExists,
    setTenants,
    login,
    listTenants,
    updateTenant,
    createTenant,
    addConfig,
    deleteConfig,
    deleteTenant,
    uploadFile,
  };
};

const APIContext = createContext({});

export const APIProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const value = useProvideAPI();

  return <APIContext.Provider value={value}>{props.children}</APIContext.Provider>;
};

export const useAPI = () => useContext(APIContext) as ReturnType<typeof useProvideAPI>;
