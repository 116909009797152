import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  HTMLChakraProps,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { useAPI } from '../../hooks/api';
import { PasswordField } from './PasswordField';

export const LoginForm = (props: HTMLChakraProps<'form'>) => {
  const { isAuthLoading, isAuthenticated, login } = useAPI();
  const [response, setResponse] = React.useState<null | {
    success: Boolean;
    message: String | null;
  }>(null);
  return (
    <chakra.form
      onSubmit={(e: any) => {
        setResponse(null);
        e.preventDefault();
        if (e?.target[0]?.value && e?.target[2]?.value) {
          login(e.target[0].value as string, e.target[2].value as string).then(result => {
            if (!result.success) setResponse(result);
          });
        }
      }}
      {...props}>
      <Stack spacing="6">
        <FormControl id="email">
          <FormLabel>Username</FormLabel>
          <Input name="username" required />
        </FormControl>
        <PasswordField />
        <Button
          type="submit"
          colorScheme="brand"
          size="lg"
          fontSize="md"
          isLoading={isAuthLoading || isAuthenticated}
          loadingText="Signing in">
          Sign in
        </Button>
        <Text fontSize="sm" color="red.500">
          {response?.message}
        </Text>
      </Stack>
    </chakra.form>
  );
};
