import { Box } from '@chakra-ui/layout';
import { useColorModeValue } from '@chakra-ui/system';
import React from 'react';
import { TenantListCard } from './TenantListCard';

export const Dashboard = () => {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'inherit')}
      minH="100vh"
      py="12"
      px={{ base: '4', lg: '8' }}>
      <TenantListCard />
    </Box>
  );
};
