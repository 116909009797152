import { Input, Text } from '@chakra-ui/react';
import React from 'react';

const SimpleInput = ({
  label,
  value,
  setValue,
  style,
  disabled,
  inputSize = 'md',
  isInvalid,
}: {
  label?: string;
  value: any;
  disabled?: boolean;
  setValue: (val: any) => any;
  style?: React.CSSProperties;
  inputSize?: 'md' | 'lg' | 'sm' | 'xs' | undefined;
  isInvalid?: boolean;
}) => {
  const handleChange = (event: { target: { value: any } }) => setValue(event.target.value);

  const statusStyle: React.CSSProperties = disabled ? { backgroundColor: '#ddd' } : {};

  return (
    <div style={{ width: '100%' }}>
      {label && (
        <Text mb="1" mt="4" fontWeight="medium" color="blackAlpha.700">
          {label}
        </Text>
      )}
      <Input
        disabled={disabled}
        value={value}
        onChange={handleChange}
        placeholder=""
        size={inputSize}
        my={label ? undefined : '1'}
        style={{ ...style, ...statusStyle }}
        borderColor={isInvalid ? 'red.500' : undefined}
        focusBorderColor={isInvalid ? 'red.500' : undefined}
        borderWidth={isInvalid ? '1px' : undefined}
      />
    </div>
  );
};

export default SimpleInput;
